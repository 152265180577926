import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/20/solid";
import Link from "next/link";
import { Fragment, useState } from "react";
import Router from "next/router";
import { useSupabaseClient } from "~/lib/AuthProvider";
import NewProjectModal from "../portfolio/NewProjectModal";
import { useUserProfileContext } from "~/lib/UserContext";
import Image from "next/image";
import Logo from "./Logo";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HeaderFrosted() {
  const [open, setOpen] = useState(false);
  const supabase = useSupabaseClient();

  const { user, profile } = useUserProfileContext();

  // TODO - Stop this from double-firing when a page is loaded
  if (profile) {
    window.rudderanalytics.identify(profile.email, {
      name: profile.full_name,
      created_at: profile.created_at,
      updated_at: profile.updated_at,
      last_signed_in_at: profile.last_signed_in_at,
      username: profile.username,
      linkedin: profile.linkedin,
      headline: profile.headline,
      intro: profile.intro,
      location: profile.location,
      current_company: profile.current_company_name,
      featured_school: profile.featured_school_name,
      resume: profile.resume,
      referrer: profile.referrer,
      core_profile_completion: profile.core_profile_completion,
      looking_for_work_status: profile.looking_for_work_status,
      looking_for_work_updated: profile.looking_for_work_updated,
      professions: profile.professions,
      portfolio_goals: profile.portfolio_goals,
      career_goals: profile.career_goals,
    });
  }

  // ATTTEMPTING TO DETECT THE AVERAGE COLOR OF THE BACKGROUND IMAGE AND PROGRAMMATICALLY CHANGE THE NAV BASED ON THAT
  // // create an image element and set its source
  // const img = new Image();
  // img.src = "your-image-url.jpg";

  // // wait for the image to load
  // img.onload = () => {
  //   // create a canvas element and draw the image on it
  //   const canvas = document.createElement("canvas");
  //   canvas.width = img.width;
  //   canvas.height = img.height;
  //   const ctx = canvas.getContext("2d");
  //   ctx.drawImage(img, 0, 0, img.width, img.height);

  //   // get the pixel data of the canvas
  //   const pixelData = ctx.getImageData(0, 0, img.width, img.height).data;

  //   // calculate the average color of the image
  //   let r = 0,
  //     g = 0,
  //     b = 0;
  //   for (let i = 0; i < pixelData.length; i += 4) {
  //     r += pixelData[i];
  //     g += pixelData[i + 1];
  //     b += pixelData[i + 2];
  //   }
  //   const pixelCount = pixelData.length / 4;
  //   const avgR = Math.round(r / pixelCount);
  //   const avgG = Math.round(g / pixelCount);
  //   const avgB = Math.round(b / pixelCount);

  //   // output the average color
  //   console.log(
  //     `The average color of the image is rgb(${avgR}, ${avgG}, ${avgB})`
  //   );
  // };

  console.log("profile embed", profile?.analytics_embed_id);
  const signedInNavigation = [
    { name: "Portfolio", href: `/s/${profile?.username}`, current: false },
    // { name: "Insights", href: "/insights", current: false },
    ...(profile?.analytics_embed_id
      ? [{ name: "Insights", href: "/insights", current: false }]
      : []),
    { name: "Manage", href: "/manage", current: false },
    { name: "Explore", href: "/explore", current: true },
  ];

  // // If the user has an analytics embed id, add the analytics page to the navigation
  // if (
  //   profile?.analytics_embed_id !== null &&
  //   profile?.analytics_embed_id !== ""
  // ) {
  //   signedInNavigation.push({
  //     name: "Insights",
  //     href: "/insights",
  //     current: false,
  //   });
  // }

  const guestNavigation = [
    { name: "Explore", href: "/explore", current: true },
    // { name: "Insights", href: "/insights", current: false },
    // { name: "Portfolio", href: `/s/${profile?.username}`, current: false },
    // { name: "Explore", href: "/explore", current: false },
  ];

  const guestNavigationPart2 = [
    { name: "Sign In", href: "/signin", current: true },
    { name: "Sign Up", href: "/", current: false },
  ];

  const signedInDropdownNavigation = [
    // { name: "Account", href: "/account" }
  ];

  // const guestDropdownNavigation = [
  //   { name: "Sign Out", href: "/" },
  //   { name: "Sign Out", href: "/" },
  // ];

  async function signOut() {
    window.rudderanalytics.reset();
    const { error } = await supabase.auth.signOut();
    Router.push("/signin");
  }

  return (
    <>
      <div className="relative">
        {/* <div className="absolute inset-0 bg-gray-100 bg-opacity-50 backdrop-blur-lg backdrop-filter"></div> */}
        <Disclosure
          as="nav"
          className="absolute z-40 h-16 w-full bg-white bg-opacity-70 shadow-lg backdrop-blur backdrop-filter"
        >
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div className="-ml-2 mr-2 flex items-center md:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-800 transition-all hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                    <div className="flex flex-shrink-0 items-center">
                      <Link
                        href="/"
                        className="mr-4 mb-1 h-10 w-auto items-center text-3xl lg:block"
                      >
                        <Logo />
                      </Link>
                    </div>
                    <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                      {/* DESKTOP NAVIGATION ------------------------------------------------------------------- */}

                      {!user ? (
                        <>
                          {guestNavigation.map((item) => (
                            <Link
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                // item.current
                                // ? "bg-gray-100 dark:bg-gray-900 dark:text-white"
                                // :
                                "font-semibold transition-all dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white",
                                "rounded-md px-3 py-2 text-sm text-gray-700 hover:text-gray-500"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </Link>
                          ))}
                        </>
                      ) : (
                        <>
                          {signedInNavigation.map((item) => (
                            <Link
                              key={item.name}
                              href={item.href}
                              className={classNames(
                                // item.current
                                // ? "bg-gray-100 dark:bg-gray-900 dark:text-white"
                                // :
                                "font-semibold  transition-all dark:text-gray-300 dark:hover:bg-gray-700 dark:hover:text-white",
                                "rounded-md px-3 py-2 text-sm text-gray-700 hover:text-gray-500"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.name}
                            </Link>
                          ))}
                        </>
                      )}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <button
                        type="button"
                        className="relative ml-4 mr-2 inline-flex items-center rounded-md border border-transparent bg-indigo-500 px-4 py-2 text-sm font-semibold text-white shadow-sm transition-all hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-800"
                        onClick={() =>
                          user !== null ? setOpen(true) : Router.push("/")
                        }
                      >
                        <PlusIcon
                          className="-ml-1 mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        <span>Add Project</span>
                      </button>
                    </div>

                    {/* Profile dropdown ----------------------------------------------------------------------- */}
                    <div className="hidden md:ml-2 md:flex md:flex-shrink-0 md:items-center">
                      {!user ? (
                        <>
                          <Link
                            href="/signin"
                            className="block py-2 pl-2 pr-4 text-sm font-semibold text-gray-700"
                          >
                            Sign In
                          </Link>
                          <Link
                            href="/"
                            className="block px-4 py-2 text-sm font-semibold text-gray-700"
                          >
                            Sign Up
                          </Link>
                        </>
                      ) : (
                        <Menu as="div" className="relative ml-3">
                          <div>
                            <Menu.Button className="flex rounded-full text-sm ring-1 ring-gray-300 focus:outline-none focus:ring-1 focus:ring-white focus:ring-offset-2">
                              <span className="sr-only">Open user menu</span>
                              {profile?.avatar !== null &&
                              profile?.avatar !== "" ? (
                                <Image
                                  className="h-10 w-10 rounded-full"
                                  src={`https://chmqmeyyaiwfybqgcdoy.supabase.co/storage/v1/object/public/users/${profile?.id}/${profile?.avatar}`}
                                  alt={
                                    profile?.first_name +
                                    " " +
                                    profile?.last_name
                                  }
                                  height={128}
                                  width={128}
                                />
                              ) : (
                                <div className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-lg text-gray-500 dark:bg-gray-700">
                                  {profile?.first_name?.charAt(0).toUpperCase()}
                                </div>
                              )}
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white bg-opacity-95 py-1 shadow-lg ring-1 ring-black ring-opacity-5 backdrop-blur backdrop-filter focus:outline-none">
                              {signedInDropdownNavigation.map((item) => (
                                <Menu.Item key={item.name}>
                                  {({ active }) => (
                                    <Link
                                      href={item.href}
                                      className={classNames(
                                        active ? "bg-gray-100" : "",
                                        "block px-4 py-2 text-sm text-gray-700"
                                      )}
                                    >
                                      {item.name}
                                    </Link>
                                  )}
                                </Menu.Item>
                              ))}
                              <div className="block w-full truncate px-4 py-2 text-left text-sm text-gray-700">
                                {user?.email}
                              </div>
                              <button
                                onClick={() => signOut()}
                                className="block w-full px-4 py-2 text-left text-sm text-gray-700 transition-all hover:bg-gray-200"
                              >
                                Sign Out
                              </button>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* MOBILE NAVIGATION --------------------------------------------------------------------- */}

              <Disclosure.Panel className="bg-gray-100 shadow-lg md:hidden ">
                <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3 ">
                  {!user ? (
                    <>
                      {guestNavigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className={classNames(
                            // item.current
                            // ? "bg-gray-900 text-white"
                            // :
                            "text-gray-700 transition-all hover:bg-gray-200",
                            "block rounded-md px-3 py-2 text-sm "
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                      {guestNavigationPart2.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className={classNames(
                            // item.current
                            // ? "bg-gray-900 text-white"
                            // :
                            "text-gray-700 transition-all hover:bg-gray-200",
                            "block rounded-md px-3 py-2 text-sm"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </>
                  ) : (
                    <>
                      {signedInNavigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className={classNames(
                            // item.current
                            // ? "bg-gray-900 text-white"
                            // :
                            "text-gray-700 transition-all hover:bg-gray-200",
                            "block rounded-md px-3 py-2 text-sm"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                    </>
                  )}
                </div>

                {!user ? null : (
                  <div className="border-t pt-4 pb-3">
                    <div className="flex items-center px-5 sm:px-6">
                      {profile?.avatar !== null && profile?.avatar !== "" ? (
                        <Image
                          className="h-10 w-10 rounded-full ring-1 ring-gray-300"
                          src={`https://chmqmeyyaiwfybqgcdoy.supabase.co/storage/v1/object/public/users/${profile?.id}/${profile?.avatar}`}
                          alt={profile?.first_name + " " + profile?.last_name}
                          width={128}
                          height={128}
                        />
                      ) : (
                        <div className="mb- flex h-10 w-10 items-center justify-center rounded-full bg-gray-200 text-lg text-gray-500 ring-1 ring-gray-300 dark:bg-gray-700">
                          {profile?.first_name?.charAt(0).toUpperCase()}
                        </div>
                      )}
                      <div className="ml-3">
                        <div className="truncate text-sm font-medium text-gray-600">
                          {user?.email}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 space-y-1 px-2 sm:px-3">
                      {signedInDropdownNavigation.map((item) => (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          href={item.href}
                          className="block rounded-md px-3 py-2 text-sm font-medium text-gray-400 transition-all hover:bg-gray-700 hover:text-white"
                        >
                          {item.name}
                        </Disclosure.Button>
                      ))}
                      <button
                        onClick={() => signOut()}
                        className="block w-full rounded-md px-4 py-2 text-left text-sm text-gray-700 transition-all hover:bg-gray-200"
                      >
                        Sign Out
                      </button>
                    </div>
                  </div>
                )}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <NewProjectModal open={open} setOpen={setOpen} />
      </div>
    </>
  );
}
