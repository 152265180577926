import NewProjectForm from "./NewProjectForm";
import Modal from "~/components/forms/general/Modal";

export default function NewProjectModal({ open, setOpen }) {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      heading="Add new project"
      description="Share a few sentences about what you worked on, including the problem, your solution details, and any success metrics or indicators you received"
    >
      <NewProjectForm onFormSubmit={() => setOpen(false)} />
    </Modal>
  );
}
