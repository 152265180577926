import { useRouter } from "next/router";
import Script from "next/script";
import { useState, useEffect } from "react";
import { createBrowserSupabaseClient } from "@supabase/auth-helpers-nextjs";
import { AuthProvider } from "~/lib/AuthProvider";
import UserProfileProvider from "~/lib/UserContext";
import Layout from "~/components/layout/Layout";
import { RudderInitialize } from "~/lib/RudderInitialize";
import "~/styles/globals.css";

export default function App({ Component, pageProps, user }) {
  const [supabaseClient] = useState(() => createBrowserSupabaseClient());
  const router = useRouter();

  useEffect(() => {
    RudderInitialize();
  }, []);

  //  Prevent expired email and signup link. Note: This may not work where the user has never confirmed their email and the code is outdated. Need to test.
  const parseHashParameters = (hash) => {
    return hash
      .substring(1)
      .split("&")
      .map((param) => param.split("="))
      .reduce((params, [key, value]) => {
        params[key] = value
          ? decodeURIComponent(value.replace(/\+/g, " "))
          : "";
        return params;
      }, {});
  };

  useEffect(() => {
    const { pathname, asPath } = router;
    const hashIndex = asPath.indexOf("#");
    const hash = hashIndex !== -1 ? asPath.substring(hashIndex) : "";

    if (hash) {
      const hashParams = parseHashParameters(hash);
      const { error } = hashParams;

      if (pathname !== "/signin" && error === "unauthorized_client") {
        router.push("/signin?signin_error=true");
      }
    }
  }, [router.query, router.pathname]);

  useEffect(() => {
    // Track page views
    const handleRouteChange = () => window.rudderanalytics.page();
    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return (
    <>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}');
        `}
      </Script>

      {!router.pathname.includes("onboard") && (
        <Script
          src="//code.tidio.co/pxbgrufbffphdfxskkm5jzteeux0b4in.js"
          async
        ></Script>
      )}

      <AuthProvider supabaseClient={supabaseClient}>
        <UserProfileProvider>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </UserProfileProvider>
      </AuthProvider>
    </>
  );
}
