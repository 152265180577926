import Image from "next/image";

export default function Logo() {
  return (
    <div className="mx-auto flex flex-row items-center">
      <div className="h-10 w-10">
        <Image
          src="https://chmqmeyyaiwfybqgcdoy.supabase.co/storage/v1/object/public/application/showspace-logo-300.png"
          alt="Showspace Logo"
          width={256}
          height={256}
        />
      </div>
      <div>
        <div className="ml-2 items-center pt-1 text-xl font-semibold tracking-tight text-gray-800 dark:text-white ">
          Showspace
        </div>
        <div className="ml-[75px] -mt-1 items-center pl-2.5 text-xs font-semibold tracking-tight text-gray-800 dark:text-white ">
          Beta
        </div>
      </div>
    </div>
  );
}
