import HeaderFrosted from "./HeaderFrosted";
import HeaderStandard from "./HeaderStandard";
import FooterSlim from "./FooterSlim";
import FooterLoggedOut from "./FooterLoggedOut";
import Footer from "./Footer";
import { useRouter } from "next/router";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import localFont from "next/font/local";
import { useUserProfileContext } from "~/lib/UserContext";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const circular = localFont({
  src: [
    {
      path: "../../fonts/Circular-Regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../fonts/Circular-Italic.woff2",
      weight: "400",
      style: "italic",
    },
    {
      path: "../../fonts/Circular-Medium.woff2",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../fonts/Circular-Bold.woff2",
      weight: "700",
      style: "normal",
    },
  ],
  variable: "--font-circular",
});

function Layout(props) {
  const router = useRouter();

  const { user, profile } = useUserProfileContext();

  const showHeaderStandard =
    router.pathname === "/signin" ||
    router.pathname === "/signup" ||
    router.pathname === "/" ||
    router.query.embed === "true" ||
    router.pathname.includes("/onboard") ||
    router.pathname.includes("/s/") ||
    router.pathname.includes("/company/") ||
    router.pathname.includes("/school/")
      ? false
      : true;

  const showHeaderFrosted =
    (router.pathname.includes("/s/") ||
      router.pathname.includes("/company/") ||
      router.pathname.includes("/school/")) &&
    router.query.header !== "false"
      ? true
      : false;

  const showFooterLoggedOut =
    router.pathname === "/" || (user === null && router.pathname !== "/signin")
      ? true
      : false;

  const showFooterSlim = router.pathname === "/signin" ? true : false;

  const showFooter =
    user !== null &&
    (router.pathname === "/signup" ||
    router.pathname === "/" ||
    router.pathname === "/signin" ||
    router.pathname.includes("/onboard/") ||
    router.query.embed === "true"
      ? false
      : true);

  const isEmbedRoute = router.pathname.startsWith("/embed");

  if (isEmbedRoute) {
    return (
      <div className={`${circular.variable} font-sans`}>{props.children}</div>
    );
  }

  return (
    <div
      className={`min-h-screen ${
        router.query.embed === "true" || router.pathname.includes("/insights")
          ? "bg-white"
          : "bg-gray-100"
      }`}
    >
      <div className={`${circular.variable} font-sans`}>
        {showHeaderStandard && <HeaderStandard />}
        {showHeaderFrosted && <HeaderFrosted />}

        <main
          className={classNames(
            router.pathname.includes("/p/") ? "mt-12 px-1" : "px-4",

            router.pathname.includes("/onboard/") ||
              router.pathname.includes("/s/") ||
              router.pathname.includes("/company/") ||
              router.pathname.includes("/school/") ||
              router.pathname.includes("/insights")
              ? "px-0 py-0"
              : "max-w-7xl sm:py-6",

            router.pathname.includes("/company/") ||
              router.pathname.includes("/school/") ||
              router.pathname.includes("/s/") ||
              router.pathname.includes("/p/") ||
              router.pathname.includes("/onboard/")
              ? ""
              : "pb-12 pt-8",
            "relative mx-auto",

            router.pathname.includes("/blog/") ||
              router.pathname.includes("/resources/")
              ? "my-20"
              : null,

            router.pathname.includes("/account") ? "my-1" : null
          )}
        >
          {props.children}
        </main>
        {showFooterLoggedOut && <FooterLoggedOut />}
        {showFooterSlim && <FooterSlim />}
        {showFooter && <Footer />}
        <ToastContainer />
      </div>
    </div>
  );
}

export default Layout;
