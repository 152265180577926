import Link from "next/link";
import { useRouter } from "next/router";

export default function FooterLoggedOut() {
  const router = useRouter();
  const currentYear = new Date().getFullYear();

  return (
    <footer
      aria-labelledby="footer-heading"
      className={router.pathname === "/insights" ? "bg-white" : "bg-gray-100"}
    >
      <div className="mx-auto mt-12 max-w-7xl border-t border-gray-200 px-4 pb-6 lg:pb-16">
        <div className="mx-auto mt-6 justify-between sm:flex sm:px-4">
          <div className="first-letter flex flex-col justify-between space-y-2 sm:space-y-0.5">
            <Link
              href="/explore"
              className="text-center text-sm text-gray-400 transition-all hover:text-gray-500 sm:mb-0 sm:text-left"
            >
              Explore
            </Link>
            <Link
              href="/blog/how-to-use-your-portfolio-to-get-hired"
              className="text-center text-sm text-gray-400 transition-all hover:text-gray-500 sm:mb-0 sm:text-left"
            >
              How to use a portfolio to get hired
            </Link>
            <Link
              href="/directory/projects"
              className="text-center text-sm text-gray-400 transition-all hover:text-gray-500 sm:text-left"
            >
              Projects directory
            </Link>

            <Link
              href="/directory/portfolios"
              className="text-center text-sm text-gray-400 transition-all hover:text-gray-500 sm:text-left"
            >
              Portfolios directory
            </Link>
          </div>
          <div className="flex flex-col space-y-2 sm:space-y-0.5">
            <div className="flex flex-col justify-center space-y-2  sm:mb-0  sm:space-y-0.5 md:justify-end">
              <Link
                href="/privacy"
                className="text-center text-sm text-gray-400 transition-all hover:text-gray-500 sm:mb-0 sm:text-right"
              >
                Privacy policy
              </Link>
              <Link
                href="/terms"
                className="text-center text-sm text-gray-400 transition-all hover:text-gray-500 sm:mb-0 sm:text-right"
              >
                Terms of service
              </Link>
            </div>
          </div>
        </div>
        <div className="mt-6 w-full border-t">
          <p className="mt-6 mb-2 text-center text-sm text-gray-400">
            Sponsored by <Link
              href="https://endorsed.ai/?ref=showspace"
              target="_blank"
            >
              Endorsed:
            </Link>{" "}
            <Link
              href="https://endorsed.ai/ai-resume-screening-software?ref=showspace"
              target="_blank"
            >
              AI Resume Screening Software
            </Link>
          </p>
          <div className="text-center text-sm text-gray-400">
            &copy; {currentYear} Showspace
          </div>
        </div>
      </div>
    </footer>
  );
}
