import { useRef, useState, useEffect } from "react";
import { useRouter } from "next/router";
import { useSupabaseClient } from "~/lib/AuthProvider";
import { useAuth } from "~/lib/AuthProvider";
import { useUserProfileContext } from "~/lib/UserContext";
import HelperText from "~/components/forms/general/HelperText";
import LoadingSpinner from "~/components/elements/LoadingSpinner";
import { PluralizedEntityType } from "~/types";
import ButtonPrimary from "~/components/elements/ButtonPrimary";
import TextLinkButton from "../elements/TextLinkButton";
import { motion } from "framer-motion";

interface NewProjectFormProps {
  onFormSubmit?: () => void;
  onboarding?: boolean;
  withMotion?: boolean;
}

const variants = {
  hidden: { opacity: 0, scale: 0 },
  visible: (i) => ({
    opacity: 1,
    scale: [1, 1.1, 1, 1.05, 1],
    transition: {
      delay: i * 0.075,
      scale: {
        type: "spring",
        stiffness: 260,
        damping: 20,
        duration: 0.2,
      },
    },
  }),
};

export default function NewProjectForm({
  onboarding = false,
  onFormSubmit,
  withMotion,
}: NewProjectFormProps) {
  const [prompt, setPrompt] = useState("");
  const [formError, setFormError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const router = useRouter();
  const supabase = useSupabaseClient();
  const { user } = useAuth(); // TODO - See if we can remove this and use the profile from the context
  const { profile } = useUserProfileContext();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  // Autofocus
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  // to prevent signin bug reload page once if user is not signed in after 1 second
  useEffect(() => {
    const reloadAttempts = parseInt(
      sessionStorage.getItem("reloadAttempts") || "0",
      10
    );

    if (reloadAttempts < 2) {
      const timer = setTimeout(() => {
        if (!user) {
          if (reloadAttempts === 0) {
            sessionStorage.setItem(
              "reloadAttempts",
              (reloadAttempts + 1).toString()
            );
            router.reload();
          } else {
            sessionStorage.setItem("reloadAttempts", "0");
            router.push("/signin?signin_error=unauthorized_client");
          }
        } else {
          sessionStorage.setItem("reloadAttempts", "0");
        }
      }, 1000);

      return () => clearTimeout(timer);
    } else {
      sessionStorage.setItem("reloadAttempts", "0");
    }
  }, [user, router]);

  // Handle creating new project and redirecting user
  const handleCreateProject = async (e, setting) => {
    if (prompt.length === 0 && router.pathname === "/onboard") {
      alert(
        "Please share details about your project so your AI can write you a draft!"
      );
      return;
    }

    setIsLoading(true);

    const { data, error } = await supabase
      .from(PluralizedEntityType.Projects)
      .insert([
        {
          path_created_at: router.pathname,
          updated_at: new Date(),
          creator: user.id,
          visibility: "portfolio",
        },
      ])
      .select()
      .single();

    if (error) {
      const errorMessage = `Error creating project with creator [${user.id}]: ${error.message}`;
      console.error(errorMessage);
      throw new Error(errorMessage);
    }

    if (data) {
      console.log(`Created project data: ${JSON.stringify(data)}`);
      window.rudderanalytics.track("project_created", {
        project_id: data.id,
        prompt,
      });

      setFormError(null);
    }

    if (error) {
      console.error(error);
      throw error;
    }

    await handleCreateContributor(data.id, setting);
  };

  const handleCreateContributor = async (project_id, setting) => {
    const { data, error } = await supabase
      .from("project_contributors")
      .insert([
        {
          project_id: project_id,
          contributor_id: user.id,
          permission: "Admin",
          state: "Approved",
          updated_at: new Date(),
          order: 1,
        },
      ])
      .select()
      .single();

    if (data) {
      console.log("Contributors post response: ", data);
    }

    if (error) {
      console.error(error);
      throw error;
    }

    console.log("data", data);

    const breadcrumbMetadata = `refPageSlug=${
      profile.username
    }&refPageType=user&refPageLabel=${profile.first_name}${
      profile.last_name ? " " + profile.last_name : ""
    }`;

    // TODO - Figure out how to use the router to push to the new project page WHILE ALSO clearing all of the page data when the current page is a project page.
    if (setting === "blank_page") {
      console.log(`Routing for 'blank_page' to /p/${data.project_id}}`);
      window.location.href = `/p/${data.project_id}?referrer=${router.pathname}&${breadcrumbMetadata}`;
    } else {
      console.log(
        `Routing for '${setting}' page to /p/${data.project_id}}?prompt=${prompt}&referrer=${router.pathname}`
      );
      window.location.href = `/p/${data.project_id}?prompt=${prompt}&referrer=${router.pathname}&${breadcrumbMetadata}`;
    }

    if (onFormSubmit !== undefined) {
      onFormSubmit();
    }
  };

  const jsx = (
    <>
      <div>
        <div className="mt-3 text-left sm:mt-5">
          <div>
            <div className="mx-auto mt-1 max-w-xl">
              <textarea
                id="description"
                name="description"
                className={`text-input ${onboarding ? "onboarding" : null}`}
                rows={6}
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
                ref={inputRef}
              />
            </div>
            {onboarding ? null : (
              <HelperText helperText="3-5 sentences recommended" />
            )}
          </div>
        </div>
      </div>
      <div
        className={`mx-auto mt-5 w-60 pb-2 sm:mt-6 ${
          onboarding ? "mb-3" : null
        }`}
      >
        {isLoading ? (
          <div className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-semibold text-white shadow-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm">
            <LoadingSpinner classes="text-white" />
          </div>
        ) : (
          <ButtonPrimary
            onClick={(e) => handleCreateProject(e, "generate_template")}
          >
            Create Project
          </ButtonPrimary>
        )}

        {onboarding ? null : (
          <div className="flex flex-col justify-center">
            <div className="mb-1 mt-2 text-center text-sm text-gray-500">
              or
            </div>
            <TextLinkButton
              onClick={(e) => handleCreateProject(e, "blank_page")}
            >
              Create Blank Page
            </TextLinkButton>
          </div>
        )}
      </div>
    </>
  );

  return withMotion ? (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={variants}
      custom={1}
    >
      {jsx}
    </motion.div>
  ) : (
    jsx
  );
}
