import { createContext, useContext, useState, useEffect } from "react";
import { useAuth, useSupabaseClient } from "~/lib/AuthProvider";
import { PluralizedEntityType } from "~/types";

interface UserProfileContextType {
  user: any;
  profile: any;
  isProfileReady: boolean;
}

const UserProfileContext = createContext<UserProfileContextType | undefined>(
  undefined
);

const useUserProfileContext = () => {
  const context = useContext(UserProfileContext);
  if (!context)
    throw new Error(
      "useUserProfileContext must be used within UserProfileProvider!"
    );
  return context;
};

const UserProfileProvider = ({ children }) => {
  const [profile, setProfile] = useState(null);
  const { user } = useAuth();

  const supabase = useSupabaseClient();

  useEffect(() => {
    const fetchProfile = async () => {
      const { data, error } = await supabase
        .from(PluralizedEntityType.CompletedUsers)
        .select("*")
        .eq("id", user.id)
        .single();
      if (error) {
        const errorMessage = `Failed to fetch completed user profile for user ${
          user.id
        }: ${JSON.stringify(error)}`;
        console.error(errorMessage);
      }
      if (data) setProfile(data);
    };

    if (user?.id) {
      fetchProfile();
    }

    if (user === null && profile) {
      setProfile(null);
    }
  }, [user?.id]);

  return (
    <UserProfileContext.Provider
      value={{ user, profile, isProfileReady: !!user && !!profile }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileProvider;
export { useUserProfileContext };
