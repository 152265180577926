import { PostgrestError } from "@supabase/supabase-js";

export enum PluralizedEntityType {
  AIGenerations = "ai_generations",
  Companies = "companies",
  CompletedUsers = "users_complete",
  CompletedProjects = "projects_complete",
  CompletedContributors = "contributors_complete",
  Industries = "industries",
  Profiles = "profiles",
  Projects = "projects",
  ProjectSkills = "project_skills",
  ProjectContributors = "project_contributors",
  CompletedProjectContributors = "project_contributors_complete",
  Skills = "skills",
  Users = "users",
  WaitlistUsers = "waitlist_users",
}

export interface Entity<ID_TYPE extends number | string> {
  id: ID_TYPE;
}

export type PluralTypeToEntityType = {
  [PluralizedEntityType.Companies]: Company;
  [PluralizedEntityType.Projects]: Project;
  [PluralizedEntityType.Users]: User;
};

// TODO | Audit and updating of types
export interface WaitlistUser {
  email: string;
  first_name: string;
  last_name: string;
  linkedin: string;
}

// TODO | Audit and updating of types
export interface CompletedUser extends Entity<string> {
  avatar: string | null;
  cover_image: string | null;
  current_company_id: string | null;
  current_company_logo: string | null;
  current_company_name: string | null;
  current_company_slug: string | null;
  crypto_wallet: string | null;
  email: string;
  facebook: string | null;
  featured_school_id: string | null;
  featured_school_logo: string | null;
  featured_school_name: string | null;
  featured_school_slug: string | null;
  first_name: string;
  full_name: string | null;
  github: string | null;
  headline: string | null;
  instagram: string | null;
  intro: string | null;
  last_name: string;
  linkedin: string | null;
  location: string | null;
  medium: string | null;
  opensea: string | null;
  personal_website: string | null;
  resume: string | null;
  tiktok: string | null;
  twitter: string | null;
  username: string;
  youtube: string | null;
}

export interface User extends Entity<string> {
  admin: boolean;
  // There are two records in the DB that have ''
  // TODO | Replace them with null & remove this.
  analytics_embed_id: string | "" | null;
  avatar: string | "" | null;
  beta: boolean | null;
  cover_image: string | null;
  created_at: string;
  // There is one records in the DB that has ''
  // TODO | Replace them with null & remove this.
  crypto_wallet: string | "" | null;
  current_company: number | null;
  // There are two records in the DB that have ''
  // TODO | Replace them with null & remove this.
  facebook: string | "" | null;
  featured_school: number | null;
  first_name: string;
  // There are two records in the DB that have ''
  // TODO | Replace them with null & remove this.
  github: string | "" | null;
  // There are 11 records in the DB that have ''
  // TODO | Replace them with null & remove this.
  headline: string | "" | null;
  indexable: boolean;
  // There is 1 records in the DB that has ''
  // TODO | Replace them with null & remove this.
  instagram: string | "" | null;
  internal_portfolio_status: "almost_complete" | "complete" | null;
  // There are 10 records in the DB that have ''
  // TODO | Replace them with null & remove this.
  intro: string | null;
  last_name: string;
  // There are 18 records in the DB that have ''
  // TODO | Replace them with null & remove this.
  // A bunch of these are mixes of vanity ids, full URLs, and names
  linkedin: string | null;
  // There are 9 records in the DB that have ''
  // TODO | Replace them with null & remove this.
  location: string | "" | null;
  // There is 1 records in the DB that has ''
  // TODO | Replace them with null & remove this.
  medium: string | "" | null;
  onboard_portfolio_goals: string | null;
  onboard_career_goals: string | null;
  onboard_looking_for_work: string | null;
  onboard_professions: string | null;
  // There is 1 records in the DB that has ''
  // TODO | Replace them with null & remove this.
  opensea: string | "" | null;
  personal_connection: boolean | null;
  // There is 3 records in the DB that has ''
  // TODO | Replace them with null & remove this.
  personal_website: string | "" | null;
  // There are 2 records in the DB that has ''
  // TODO | Replace them with null & remove this.
  ref: string | "" | null;
  tiktok: string | null;
  // There is 1 records in the DB that has ''
  // TODO | Replace them with null & remove this.
  twitter: string | null;
  updated_at: string;
  username: string;
  // There are 2 records in the DB that has ''
  // TODO | Replace them with null & remove this.
  youtube: string | null;
}

// TODO | Audit and updating of types
export interface Profile extends Entity<string> {
  first_name: string;
  last_name: string;
  username: string;
  avatar: string | null;
  cover_image: string | null;
  current_company_name: string | null;
  current_company_logo: string | null;
  current_company_slug: string | null;
  current_company_id: number | null;
  featured_school_name: string | null;
  featured_school_logo: string | null;
  featured_school_slug: string | null;
  featured_school_id: number | null;
  headline: string | null;
  personal_website: string | null;
  linkedin: string | null;
  intro: string | null;
  location: string | null;
  twitter: string | null;
  github: string | null;
  email: string | null;
  opensea: string | null;
  crypto_wallet: string | null;
  instagram: string | null;
  tiktok: string | null;
  facebook: string | null;
  youtube: string | null;
  medium: string | null;
  analytics_embed_id: string | null;
}

enum LegalStatus {
  Private = "Private",
  PrivatelyHeld = "Privately Held",
  Public = "Public Company",
}

enum CompanySize {
  ZeroToOneEmployees = "0-1 employees",
  OneThousandOneToFiveThousandEmployees = "1,001-5,000 employees",
  TenThousandOnePlusEmployees = "10,001+ employees",
  ElevenToFiftyEmployees = "11-50 employees",
  // There is one record in the DB to have this
  // TODO | Replace with the other option and remove this
  ElevenToFifty = "11-50",
  TwoToTenEmployees = "2-10 employees",
  // There is one record in the DB to have this
  // TODO | Replace with the other option and remove this
  TwoToTen = "2-10",
  TwoHundredOneToFiveHundredEmployees = "201-500 employees",
  FiveHundredOneToOneThousandEmployees = "501-1,000 employees",
  FiftyOneToTwoHundredEmployees = "51-200 employees",
}

export interface Industry extends Entity<number> {
  name: string;
  naics_name: string;
  naics_2017_code: number;
}

export enum OrgType {
  Company = "Company",
  School = "School",
  // There's 113 records in the DB that have ''
  // TODO | Replace them with null
  Empty = "",
}

export interface Company extends Entity<number> {
  about: string | null;
  cover_image: string | null;
  created_at: string;
  creator: number | null;
  crypto_wallet: string | null;
  facebook: string | null;
  founded: number | null;
  github: string | null;
  headquarters: string | null;
  headline: string | null;
  indexable: boolean;
  industries: Industry | null;
  instagram: string | null;
  // There are two records in the DB that have ''
  // TODO | Replace them with null & remove this.
  legal_status: LegalStatus | "" | null;
  linkedin: string | null;
  logo: string | null;
  medium: string | null;
  name: string | null;
  opensea: string | null;
  org_type: OrgType | null;
  size: CompanySize | null;
  slug: string | null;
  specialties: string | null;
  status?: "draft" | "live";
  tiktok: string | null;
  twitter: string | null;
  updated_at: string;
  website: string | null;
  youtube: string | null;
}

export enum ProjectType {
  Architecture = "Architecture",
  BusinessDevelopment = "Business Development",
  Community = "Community",
  DataAndAnalytics = "Data & Analytics",
  Design = "Design",
  Engineering = "Engineering",
  Event = "Event",
  Finance = "Finance",
  Growth = "Growth",
  HumanResources = "Human Resources",
  Marketing = "Marketing",
  Operations = "Operations",
  Photography = "Photography",
  Product = "Product",
  Project = "Project",
  Research = "Research",
  Sales = "Sales",
  Support = "Support",
  Videography = "Videography",
}

export interface Project extends Entity<number> {
  companies: Company[];
  // Format is YYYY-MM-DD
  // TODO | Consider validating this
  completed: string | null;
  // There are 6 records in the DB that have ''
  // TODO | Replace them with null & remove this.
  company: string | null;
  contributors: string | "" | null;
  cool: true | null;
  created_at: string;
  // There are 3 records in the DB that have null
  // TODO | Consider how to handle these - maybe delete them & change this to required
  creator: string | null;
  demo_link: string | null;
  description: string | null;
  // There are 2 records in the DB that have '100' and '2'
  // TODO | Consider how to handle these - maybe delete them & deprecate / delete this field
  featured_order: "100" | "2" | null;
  // There is only 1 record in the DB with a value ('Adam')
  // TODO | Consider deprecating / deleting this field
  industry: Industry | null;
  og_creator: string | null;
  one_liner: string | null;
  // Only exists for 2 records
  // TODO | Consider how to handle these - maybe delete them & deprecate / delete this field
  more_media: string | null;
  link_type: "Demo available upon request" | "Demo" | "Not available" | null;
  path_created_at: string | null;
  // Only exists with one value for 8 records
  // TODO | Consider how to handle these - maybe delete them & deprecate / delete this field
  project_lead: "David Head" | null;
  // Only exists with one value for 8 records
  // TODO | Consider how to handle these - maybe delete them & deprecate / delete this field
  project_lead_temp: 1 | null;
  project_skills: Skill[];
  published_at: string | null;
  role_type: "Founder" | "Full Time Employee" | null;
  scratchpad: string | null;
  slug: string | null;
  // Format is YYYY-MM-DD
  // TODO | Consider validating this
  start_date: string | null;
  status: "Draft" | "Inactive" | "Live" | null;
  story: string | null;
  // There are 3 records in the DB that have ''
  // TODO | Replace them with null & remove this.
  tags: string | "" | null;
  thumbnail: string | null;
  // There are 2 records in the DB that have ''
  // TODO | Replace them with null & remove this.
  title: string | "" | null;
  tools: string | null;
  type: ProjectType | null;
  updated_at: string;
  users: Profile[];
  visibility?: "portfolio" | "showspace" | "link" | "showspace" | string;
  web3_status: "On-Chain" | null;
}

export enum PermissionType {
  // Permission to modify any part of a project
  Admin = "Admin",
  // Permission to only add a contribution or update / remove an owned contribution
  Edit = "Edit",
}

export enum ContributorState {
  Approved = "Approved",
  Pending = "Pending",
}

export type CreateProjectContributor = Omit<
  ProjectContributor,
  "id" | "created_at" | "updated_at"
>;

export interface ProjectContributor extends Entity<number> {
  project_id: number;
  contributor_id: string;
  created_at: string;
  updated_at: string;
  permission: PermissionType;
  state: ContributorState;
  start_date: null;
  end_date: null;
  // This field isn't really necessary - we should store order in the project table itself instead
  order: 1;
}

export interface CompletedProjectContributor {
  first_name: string;
  last_name: string | null;
  username: string | null;
  avatar: string | "" | null;
  id: string | null;
  order: number | null;
}

export interface CompletedProject {
  project_id: number;
  created_at: string;
  updated_at: string;
  permission: PermissionType;
  state: ContributorState;
  start_date: null;
  end_date: null;
  project_completed: string | null;
  project_legacy_contributors: string | "" | null;
  project_creator: string | null;
  project_description: string | null;
  project_slug: string | null;
  project_start_date: string | null;
  project_status: "Draft" | "Inactive" | "Live" | null;
  project_story: string | null;
  project_tags: string | "" | null;
  project_thumbnail: string | null;
  project_title: string | "" | null;
  project_type: ProjectType | null;
  project_visibility?:
    | "portfolio"
    | "showspace"
    | "link"
    | "showspace"
    | string;
}

export interface RequestMetadata {
  model: string;
  temperature: number;
  top_p?: number;
  frequency_penalty?: number;
  presence_penalty?: number;
}

// TODO | Audit and updating of types
export interface AIGeneration extends Entity<number> {
  call: string;
  generated_by_user_id: number;
  project_id: number;
  prompt_prefix: string | null;
  request_metadata: RequestMetadata;
  response: string;
  response_time: number;
  user_prompt: string | null;
}

export type CreateProjectSkill = Omit<
  ProjectSkill,
  "id" | "created_at" | "updated_at"
>;

export interface ProjectSkill extends Entity<number> {
  project_id: number;
  skill_id: number;
  created_at: string;
  updated_at: string;
}

export interface Skill extends Entity<number> {
  label: string;
  status: 2;
}

export type SkillTypeaheadResponse =
  | { skills: Skill[] }
  | { error: PostgrestError };

export type ShareableCompletedPortfolioEvent = {
  user_id: string;
};

export enum TrackingEventType {
  NewCompletedPortfolio = "new_completed_portfolio",
  ShareableCompletedPortfolio = "shareable_completed_portfolio",
  UserInviteClicked = "user_invite_clicked",
  UserAddWithoutInviteClicked = "user_add_without_invite_clicked",
  UserEmailInviteInitiated = "user_email_invite_initiated",
}

export enum UserInviteUseCase {
  TagProjectTeammate = "tag_project_teammate",
  ClickProjectBadge = "click_project_badge",
}

export type UserInviteClickedEvent = {
  project_id: number;
  use_case: UserInviteUseCase;
};
