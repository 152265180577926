import Link from "next/link";

export default function FooterSlim() {
  const currentYear = new Date().getFullYear();
  return (
    <footer aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto max-w-7xl px-4 lg:pb-16">
        <div className="mt-6 border-t border-gray-200 pt-6 ">
          <p className="mt-6 mb-2 text-center text-sm text-gray-400">
            Sponsored by{" "}
            <Link href="https://endorsed.ai/?ref=showspace" target="_blank">
              Endorsed:
            </Link>{" "}
            <Link
              href="https://endorsed.ai/ai-resume-screening-software?ref=showspace"
              target="_blank"
            >
              AI Resume Screening Software
            </Link>
          </p>
          <p className="text-center text-sm text-gray-400">
            &copy; {currentYear} Showspace
          </p>
        </div>
      </div>
    </footer>
  );
}
