import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import localFont from "next/font/local";

const circular = localFont({
  src: [
    {
      path: "../../../fonts/Circular-Regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../../fonts/Circular-Medium.woff2",
      weight: "600",
      style: "normal",
    },
  ],
  variable: "--font-circular",
});

interface ModalProps {
  open?: boolean;
  setOpen?: (open: boolean) => void;
  children?: React.ReactNode;
  onSave?: any;
  onDelete?: () => void;
  deleteButtonText?: string;
  heading?: string;
  description?: string;
  completeButtonText?: string;
  extraVerticalSpace?: boolean;
  form?: boolean;
}

export default function Modal({
  open,
  setOpen,
  children,
  onSave,
  onDelete,
  deleteButtonText,
  heading,
  description,
  completeButtonText,
  extraVerticalSpace,
}: ModalProps) {
  const cancelButtonRef = useRef(null);

  return (
    <div>
      <style jsx global>{`
        :root {
          --font-circular: ${circular.style.fontFamily};
        }
      `}</style>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="pt- relative z-40"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className={`modal-target flex min-h-full items-center justify-center p-4 text-left sm:items-center sm:p-0
              ${extraVerticalSpace ? "my-12" : null}
              `}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <Dialog.Title className="text-lg font-semibold leading-6 text-gray-900">
                    {heading}
                  </Dialog.Title>
                  <p className="text-sm text-gray-800">{description}</p>

                  <div>{children}</div>

                  {completeButtonText === undefined &&
                  deleteButtonText === undefined ? null : (
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                      {completeButtonText === undefined ? null : (
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                          onClick={(event) => {
                            onSave(event);
                          }}
                        >
                          {completeButtonText}
                        </button>
                      )}

                      {deleteButtonText === undefined ? null : (
                        <div className="flex justify-center sm:justify-start">
                          <button
                            type="button"
                            className="mt-3 inline-flex rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-600  hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                            onClick={() => {
                              onDelete();
                            }}
                          >
                            {deleteButtonText}
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}
