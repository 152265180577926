import Image from "next/image";

export default function LogoMark() {
  return (
    <div className="h-11 w-11">
      <Image
        src="https://chmqmeyyaiwfybqgcdoy.supabase.co/storage/v1/object/public/application/showspace-logo-300.png"
        alt="Showspace Logo"
        width={256}
        height={256}
      />
    </div>
  );
}
